@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url(../src/assets/fonts/Inter-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url(../src/assets/fonts/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url(../src/assets/fonts/Inter-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Monsieur";
  src: url(../src/assets/fonts/MonsieurLaDoulaise-Regular.ttf)
    format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Creattion";
  src: url(../src/assets/fonts/Creattion\ Demo.otf);
}
@font-face {
  font-family: "Roobert-Medium";
  src: url(../src/assets/fonts/Roobert-Medium.ttf);
}

@layer base {

  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 1.9%;

    --radius: 0.5rem;
  }
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}
.App {
  text-align: center;
  font-family: "Inter";
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

canvas {
  font-family: "Monsieur";
}

.errormsg {
  color: red;
}

.phoneInput .PhoneInput {
  width: 100%;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.phoneInput .PhoneInputCountry {
  min-width: 20%;
  height: 45px;
  border: 1px solid lightgray;
  background: #fff;
  justify-content: center;
  border-radius: 5px;
}
.phoneInput .PhoneInputInput {
  min-width: 79.3%;
  height: 30px;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 16px;
  color: #787373;
  border: 1px solid lightgray;
}
.phoneInput .PhoneInputInput:focus {
  border: 1px solid #007bff;
}
.phoneInput .PhoneInputCountryIcon {
  width: 2rem;
  height: 1.5rem;
}

.phoneInput .errorbox {
  border: 1px solid red;
}

.stripe-form {
  width: 100%;
}

.input-element-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 18px;
  padding-left: 18px;
  padding-bottom: 16px;
}
.vertical-hr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1em;
  border: none;
  border-right: 1px solid white;
  height: 30px;
  position: relative;
}
.pac-container {
  background-color: #fff;
  background-image: none !important;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

/* ------------------------------------------------------calendar-------------------------------------------------- */
.react-calendar {
  max-width: 90%;
  background: white;
  border: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding-bottom: 15px;
  padding-top: 0;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  color: black;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 10px;
}

.react-calendar__tile--hasActive {
  background: #398aff;
  border-radius: 10px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
  border-radius: 10px;
}
.react-calendar__tile--active {
  background: #398aff;
  border: 1px solid #398aff;
  border-radius: 10px;
  color: #fff;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #398aff;
  border: 1px solid #398aff;
  border-radius: 10px;
  color: white;
}
.react-calendar__tile--active:enabled:not(:focus) {
  background: #398aff;
  border: 1px solid #398aff;
  border-radius: 10px;
  color: white;
}
.react-calendar__tile--now:enabled :focus {
  background: #398aff;
  color: white;
}

.Marquee {
  display: flex;
  overflow: hidden;
}
.Marquee-content {
  display: flex;
  animation: marquee 40s linear infinite running;
}
@-moz-keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}
@-o-keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}

.Marquee2 {
  display: flex;
  overflow: hidden;
}
.Marquee-content2 {
  display: flex;
  animation: marquee2 40s linear infinite running;
}

@-moz-keyframes marquee2 {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translate(0);
  }
}
@-webkit-keyframes marquee2 {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translate(0);
  }
}
@-o-keyframes marquee2 {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes marquee2 {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translate(0);
  }
}
